import '@/styles/globals.css'
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import { GoogleAnalytics } from "nextjs-google-analytics";
import Script from 'next/script'

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'react-notifications/lib/notifications.css';

import { Toaster } from 'react-hot-toast';

import NextNProgress from "nextjs-progressbar";
import { useEffect, useState } from 'react';

export default function App({ Component, pageProps }) {
  const [buildId, setBuildId] = useState(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    if (buildId) return;

    fetch('/api/build')
      .then(res => res.json())
      .then(data => {
        setBuildId(data?.buildId || 'N/A');
      })
  }, []);
  
  return <>
    <GoogleAnalytics trackPageViews />
    <NextNProgress
      color="#ed8936"
      options={{ easing: "ease", speed: 250 }}
    />
    <Toaster position='top-right' />
    <header className="fixed top-0 left-0 w-full z-50">
      <Navbar />
    </header>
    <Component {...pageProps} />
    <Footer buildId={buildId} />
  </>
}